<template>
    <section>
        <modal ref="CalificacionesDelivery" titulo="Calificacion del Delivery" icon="review" no-aceptar no-cancelar cerrar>
            <div class="row mx-0 px-5">
                <div class="col-6">
                    <p class="d-middle text-general f-500">
                        General
                    </p>
                </div>
                <div class="col-6 f-500 text-general ml-auto">
                    {{ agregarSeparadoresNumero(detalle.calificacion_general,1) }} <i class="icon-star f-20 orange-star" />
                </div>
            </div>
            <div class="row ml-auto justify-center f-13 text-general">
                {{ detalle.cantidad_calificaciones }} Calificaciones
            </div>
            <hr class="my-3" />
            <div v-for="(calificacion, c) in calificaciones" :key="c" class="row mx-0 px-5 py-2 text-general f-14">
                <div class="col-6">
                    <p class="d-middle ">
                        {{ calificacion.nombre }}
                    </p>
                </div>
                <div class="col-6 ml-auto">
                    {{ agregarSeparadoresNumero(calificacion.promedio,1) }} <i class="icon-star f-20 orange-star" />
                </div>
            </div>
        </modal>
    </section>
</template>
<script>
export default {
    data(){
        return{
            calificaciones: [],
            detalle:{}
        }
    },
    methods: {
        toggle(obj){
            if(obj ===  null) return
            console.log(obj);
            this.detalle = obj
            this.calificaciones = obj.calificaciones
            this.$refs.CalificacionesDelivery.toggle();

        },

    }
}
</script>
<style lang="css" scoped>
.orange-star{
    color: #FF9D32;
}
</style>
